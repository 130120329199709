var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "basic-rules-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.BasicRules.title",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save,
                additionalErrorMessage: _vm.errorMessage
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _c(
                "CForm",
                [
                  _c("ValidatedCheckbox", {
                    attrs: {
                      translationKey: "playroom.smokingAllowed",
                      "data-cy": "smokingAllowed-field",
                      field: _vm.$v.basicRules.smokingAllowed,
                      checked: _vm.$v.basicRules.smokingAllowed.$model
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.basicRules.smokingAllowed,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("ValidatedCheckbox", {
                    attrs: {
                      translationKey: "playroom.smokingAllowedIndoor",
                      "data-cy": "smokingAllowedIndoor-field",
                      field: _vm.$v.basicRules.smokingAllowedIndoor,
                      checked: _vm.$v.basicRules.smokingAllowedIndoor.$model
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.basicRules.smokingAllowedIndoor,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("ValidatedCheckbox", {
                    attrs: {
                      translationKey: "playroom.petsAllowed",
                      "data-cy": "petsAllowed-field",
                      field: _vm.$v.basicRules.petsAllowed,
                      checked: _vm.$v.basicRules.petsAllowed.$model
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.basicRules.petsAllowed,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("ValidatedCheckbox", {
                    attrs: {
                      translationKey: "playroom.partyEventAllowed",
                      "data-cy": "partyEventAllowed-field",
                      field: _vm.$v.basicRules.partyEventAllowed,
                      checked: _vm.$v.basicRules.partyEventAllowed.$model
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.basicRules.partyEventAllowed,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("ValidatedCheckbox", {
                    attrs: {
                      translationKey: "playroom.commercialImageUsageAllowed",
                      "data-cy": "commercialImageUsageAllowed-field",
                      field: _vm.$v.basicRules.commercialImageUsageAllowed,
                      checked:
                        _vm.$v.basicRules.commercialImageUsageAllowed.$model
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.basicRules.commercialImageUsageAllowed,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _c("ValidatedCheckbox", {
                    attrs: {
                      translationKey: "playroom.commercialServiceUsageAllowed",
                      "data-cy": "commercialServiceUsageAllowed-field",
                      field: _vm.$v.basicRules.commercialServiceUsageAllowed,
                      checked:
                        _vm.$v.basicRules.commercialServiceUsageAllowed.$model
                    },
                    on: {
                      "update:checked": function($event) {
                        return _vm.$set(
                          _vm.$v.basicRules.commercialServiceUsageAllowed,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _vm.allowOvernightStay
                    ? _c("ValidatedInput", {
                        attrs: {
                          translationKey: "playroom.maxPersonsOvernight",
                          field: _vm.$v.maxPersonsOvernight,
                          value: _vm.$v.maxPersonsOvernight.$model,
                          "data-cy": "max-personsOvernight-field",
                          type: "number",
                          min: "2",
                          step: "1"
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.maxPersonsOvernight,
                              "$model",
                              $event
                            )
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.allowHourBooking
                    ? _c("ValidatedInput", {
                        attrs: {
                          translationKey: "playroom.maxPersonsHourly",
                          field: _vm.$v.maxPersonsHourly,
                          value: _vm.$v.maxPersonsHourly.$model,
                          "data-cy": "max-personsHourly-field",
                          type: "number",
                          min: "2",
                          step: "1"
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.maxPersonsHourly,
                              "$model",
                              $event
                            )
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c(
            "CCard",
            [
              _c("CCardHeader", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value:
                      "components.playroomEditParts.BasicRules.info.box1.title",
                    expression:
                      "'components.playroomEditParts.BasicRules.info.box1.title'"
                  }
                ]
              }),
              _c("CCardBody", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "components.playroomEditParts.BasicRules.info.box1.text"
                    )
                  )
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }