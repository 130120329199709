var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "cancellation-rules-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.CancellationRules.title",
                titleTooltipTranslationKey:
                  "components.playroomEditParts.CancellationRules.cancellationInfo",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "CCard",
                        [
                          _c("CCardHeader", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.playroomEditParts.CancellationRules.overnightTitle"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "CCardBody",
                            [
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.overnightBefore30Days",
                                  field:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .before30Days,
                                  value:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .before30Days.$model,
                                  disabled: true
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.overnightCancellationRules
                                        .before30Days,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.overnightBefore14Days",
                                  field:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .before14Days,
                                  value:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .before14Days.$model,
                                  disabled: !_vm.playroom.allowOvernightStay
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.overnightCancellationRules
                                        .before14Days,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.overnightBefore5Days",
                                  field:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .before5Days,
                                  value:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .before5Days.$model,
                                  disabled: !_vm.playroom.allowOvernightStay
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.overnightCancellationRules
                                        .before5Days,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.overnightBefore3Days",
                                  field:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .before3Days,
                                  value:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .before3Days.$model,
                                  disabled: !_vm.playroom.allowOvernightStay
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.overnightCancellationRules
                                        .before3Days,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.overnightBefore1Days",
                                  field:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .before1Days,
                                  value:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .before1Days.$model,
                                  disabled: !_vm.playroom.allowOvernightStay
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.overnightCancellationRules
                                        .before1Days,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.overnightBeforeSameDay",
                                  field:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .beforeSameDay,
                                  value:
                                    _vm.$v.playroom.overnightCancellationRules
                                      .beforeSameDay.$model,
                                  disabled: !_vm.playroom.allowOvernightStay
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.overnightCancellationRules
                                        .beforeSameDay,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "CCard",
                        [
                          _c("CCardHeader", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.playroomEditParts.CancellationRules.hourlyTitle"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "CCardBody",
                            [
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.hourlyBefore30Days",
                                  field:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before30Days,
                                  value:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before30Days.$model,
                                  disabled: true
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.hourlyCancellationRules
                                        .before30Days,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.hourlyBefore14Days",
                                  field:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before14Days,
                                  value:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before14Days.$model,
                                  disabled: !_vm.playroom.allowHourBooking
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.hourlyCancellationRules
                                        .before14Days,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.hourlyBefore5Days",
                                  field:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before5Days,
                                  value:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before5Days.$model,
                                  disabled: !_vm.playroom.allowHourBooking
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.hourlyCancellationRules
                                        .before5Days,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.hourlyBefore3Days",
                                  field:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before3Days,
                                  value:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before3Days.$model,
                                  disabled: !_vm.playroom.allowHourBooking
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.hourlyCancellationRules
                                        .before3Days,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.hourlyBefore1Days",
                                  field:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before1Days,
                                  value:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before1Days.$model,
                                  disabled: !_vm.playroom.allowHourBooking
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.hourlyCancellationRules
                                        .before1Days,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.hourlyBefore5hours",
                                  field:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before5hours,
                                  value:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before5hours.$model,
                                  disabled: !_vm.playroom.allowHourBooking
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.hourlyCancellationRules
                                        .before5hours,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.hourlyBefore3hours",
                                  field:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before3hours,
                                  value:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before3hours.$model,
                                  disabled: !_vm.playroom.allowHourBooking
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.hourlyCancellationRules
                                        .before3hours,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c("ValidatedInput", {
                                attrs: {
                                  translationKey:
                                    "playroomCancellationRules.hourlyBefore1hours",
                                  field:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before1hours,
                                  value:
                                    _vm.$v.playroom.hourlyCancellationRules
                                      .before1hours.$model,
                                  disabled: !_vm.playroom.allowHourBooking
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.hourlyCancellationRules
                                        .before1hours,
                                      "$model",
                                      $event
                                    )
                                  },
                                  focusChange: _vm.onFocusChange
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-content",
                                    fn: function() {
                                      return [
                                        _c("span", {
                                          directives: [
                                            {
                                              name: "t",
                                              rawName: "v-t",
                                              value:
                                                "components.playroomEditParts.CancellationRules.postFixLabel",
                                              expression:
                                                "'components.playroomEditParts.CancellationRules.postFixLabel'"
                                            }
                                          ]
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name: "components.playroomEditParts.CancellationRules.info.box1"
            }
          }),
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name: "components.playroomEditParts.CancellationRules.info.box2",
              highlightName:
                "components.playroomEditParts.CancellationRules.info.box1"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }