var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "frequently-asked-questions-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.FrequentlyAskedQuestions.title",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _vm.ready
                ? _c(
                    "div",
                    [
                      _c(
                        "draggable",
                        {
                          attrs: { handle: ".drag-handle" },
                          on: {
                            start: function($event) {
                              _vm.drag = true
                            },
                            end: function($event) {
                              _vm.drag = false
                            }
                          },
                          model: {
                            value: _vm.playroom.questions,
                            callback: function($$v) {
                              _vm.$set(_vm.playroom, "questions", $$v)
                            },
                            expression: "playroom.questions"
                          }
                        },
                        _vm._l(_vm.$v.playroom.questions.$each.$iter, function(
                          question,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "CCard",
                                [
                                  _c(
                                    "CCardBody",
                                    [
                                      _c(
                                        "CRow",
                                        [
                                          _c(
                                            "CCol",
                                            {
                                              staticClass:
                                                "dragable drag-handle",
                                              attrs: { md: "1" }
                                            },
                                            [
                                              _c("CIcon", {
                                                attrs: {
                                                  name: "cis-hamburger-menu"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            {
                                              staticClass: "drag-handle",
                                              attrs: { md: "10" }
                                            },
                                            [
                                              _c("ValidatedInput", {
                                                attrs: {
                                                  field: question.question,
                                                  translationKey:
                                                    "playroomQuestion.question",
                                                  value:
                                                    question.question.$model,
                                                  "data-cy": "question"
                                                },
                                                on: {
                                                  "update:value": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      question.question,
                                                      "$model",
                                                      $event
                                                    )
                                                  },
                                                  focusChange: _vm.onFocusChange
                                                }
                                              }),
                                              _c("ValidatedTextArea", {
                                                attrs: {
                                                  field: question.answer,
                                                  translationKey:
                                                    "playroomQuestion.answer",
                                                  value: question.answer.$model,
                                                  "data-cy": "answer"
                                                },
                                                on: {
                                                  "update:value": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      question.answer,
                                                      "$model",
                                                      $event
                                                    )
                                                  },
                                                  focusChange: _vm.onFocusChange
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            {
                                              staticClass: "middle-center",
                                              attrs: { md: "1" }
                                            },
                                            [
                                              _c("DeleteButton", {
                                                attrs: {
                                                  onDelete: function() {
                                                    return _vm.onDeletedQuestion(
                                                      index
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _vm.stillAvailableCount > 0
                        ? _c(
                            "CButton",
                            {
                              staticClass: "add-button",
                              attrs: { color: "light", disabled: !_vm.canAdd },
                              on: { click: _vm.addNewQuestion }
                            },
                            [
                              _c("CIcon", { attrs: { name: "cilPlus" } }),
                              _c("span", { staticClass: "add-text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.playroomEditParts.FrequentlyAskedQuestions.addButton",
                                        {
                                          stillAvailableCount:
                                            _vm.stillAvailableCount
                                        }
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name:
                "components.playroomEditParts.FrequentlyAskedQuestions.info.box1"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }