var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { staticClass: "file-label", attrs: { for: _vm.name } }, [
      _vm.hasLink || _vm.hasDataUrl
        ? _c(
            "div",
            [
              _c("CIcon", { attrs: { name: "cisFilePdf", size: "4xl" } }),
              _vm.hasLink && !_vm.hasDataUrl
                ? _c("div", [
                    _c("a", { attrs: { href: _vm.link } }, [
                      _vm._v(_vm._s(_vm.fileName))
                    ])
                  ])
                : _vm._e(),
              _vm.hasDataUrl
                ? _c("div", [_vm._v(" " + _vm._s(_vm.fileName) + " ")])
                : _vm._e(),
              _c("br")
            ],
            1
          )
        : _vm._e(),
      !_vm.hasLink && !_vm.hasDataUrl
        ? _c("div", [
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("components.playroomEditParts.RuleUpload.dragInfo")
                  ) +
                  " "
              )
            ]),
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("components.playroomEditParts.RuleUpload.or")) +
                  " "
              )
            ]),
            _c("div", {
              directives: [
                {
                  name: "t",
                  rawName: "v-t",
                  value: "components.playroomEditParts.RuleUpload.upload",
                  expression: "'components.playroomEditParts.RuleUpload.upload'"
                }
              ],
              staticClass: "btn btn-primary"
            })
          ])
        : _vm._e()
    ]),
    _vm.hasLink || _vm.hasDataUrl
      ? _c(
          "div",
          [
            _c("DeleteButton", {
              attrs: { onDelete: _vm.onDelete, type: "text" }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }